<mat-card @list>
  <mat-card-content>
    <div *ngIf="passwordComponent.enableLowerCaseLetterRule" @items class="info-row">
      <div *ngIf="passwordComponent.containAtLeastOneLowerCaseLetter; then done else error" @flipY>
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">{{matIconDone}}</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">{{matIconError}}</mat-icon>
      </ng-template>
      <span>{{lowerCaseCriteriaMsg}}</span>
    </div>

    <div *ngIf="passwordComponent.enableUpperCaseLetterRule" @items class="info-row">
      <div *ngIf="passwordComponent.containAtLeastOneUpperCaseLetter; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">{{matIconDone}}</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">{{matIconError}}</mat-icon>
      </ng-template>
      <span>{{upperCaseCriteriaMsg}}</span>
    </div>

    <div *ngIf="passwordComponent.enableDigitRule" @items class="info-row">
      <div *ngIf="passwordComponent.containAtLeastOneDigit; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">{{matIconDone}}</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">{{matIconError}}</mat-icon>
      </ng-template>
      <span>{{digitsCriteriaMsg}}</span>
    </div>

    <div *ngIf="passwordComponent.enableSpecialCharRule" @items class="info-row">
      <div *ngIf="passwordComponent.containAtLeastOneSpecialChar; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">{{matIconDone}}</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">{{matIconError}}</mat-icon>
      </ng-template>
      <span>{{specialCharsCriteriaMsg}}</span>
    </div>

    <div *ngIf="passwordComponent.enableLengthRule" @items class="info-row">
      <div *ngIf="passwordComponent.containAtLeastMinChars; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">{{matIconDone}}</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">{{matIconError}}</mat-icon>
      </ng-template>
      <span>{{minCharsCriteriaMsg}}</span>
    </div>

    <div *ngIf="passwordComponent.customValidator" @items class="info-row">
      <div *ngIf="passwordComponent.containAtCustomChars; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">{{matIconDone}}</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">{{matIconError}}</mat-icon>
      </ng-template>
      <span>{{customCharsCriteriaMsg}}</span>
    </div>

    <div *ngIf="enableScoreInfo" @items class="info-row">
      <div *ngIf="passwordComponent.strength === 100; then done else error">
      </div>
      <ng-template #done>
        <mat-icon @positiveState color="primary">{{matIconDone}}</mat-icon>
      </ng-template>
      <ng-template #error>
        <mat-icon @negativeState color="warn">{{matIconError}}</mat-icon>
      </ng-template>
      <span>Password's strength = {{passwordComponent.strength}} %100</span>
    </div>

  </mat-card-content>
</mat-card>